<template>
  <div v-if="reservation">
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <OptionSummary v-if="showOptionSummary" :optionId="itemId" />
      </template>
    </ui-component-drawer>

    <ModalEditTotalSeats
      v-if="showEditTotalSeats"
      :showModal="showEditTotalSeats"
      :isEmployee="isEmployee"
      :isLocked="isLocked"
      :option="selectedOption"
      :onClickCancel="hideModal"
    />

    <ModalAddOption
      v-if="showAddOption"
      :showModal="showAddOption"
      :reservationSpaces="reservation.Spaces"
      :endMinutes="reservation.EndMinutes"
      :startMinutes="reservation.StartMinutes"
      :hideSelectedItems="true"
      :isEmployee="isEmployee"
      :onClickCancel="hideModal"
    />

    <ModalEditOption
      v-if="showEditOption"
      :showModal="showEditOption"
      :isEmployee="isEmployee"
      :isLocked="isLocked"
      :option="selectedOption"
      :onClickCancel="hideModal"
    />

    <ModalDeleteOption
      v-if="showDeleteOption"
      :showModal="showDeleteOption"
      :option="selectedOption"
      :onClickCancel="hideModal"
    />

    <ModalEditOptionSortOrder
      v-if="showEditSortOrder"
      :showModal="showEditSortOrder"
      :isEmployee="isEmployee"
      :isLocked="isLocked"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showAdd="!isLocked"
      :addFunction="setShowAddOption"
      :addLabel="$t('Components.Reservation.ReservationDetail.Icon_AddOption')"
      :showEdit="true"
      :editFunction="setShowEditSortOrder"
      :editLabel="'Sort options'"
    >
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationDetail.Subtitle_Options')
      }}</template>
    </ui-section-header>

    <Message
      v-if="reservation.Options.length === 0"
      :message="
        $t('Components.Reservation.ReservationDetail.Message_NoOptionsAdded')
      "
    />

    <div v-if="reservation.Options.length > 0">
      <div
        v-if="!isLocked && isEmployee && reservation.MeetingtypeId !== 2"
        class="has-margin-bottom"
      >
        <div>
          <span class="subtitle"
            >{{ $t('Components.Reservation.ReservationDetail.Text_TotalSeats')
            }}{{ reservation.TotalSeats }}</span
          >
          <a @click="setShowEditTotalSeats" class="is-size-7 is-hidden-print">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'edit']" />
            </span>
            <span>{{
              $t(
                'Components.Reservation.ReservationDetail.Icon_ChangeTotalSeats'
              )
            }}</span>
          </a>
        </div>
      </div>

      <div v-for="(cat, cIndex) in categories" :key="cat">
        <div
          class="has-text-weight-bold has-margin-bottom-half"
          :class="{ 'has-margin-top': cIndex !== 0 }"
          v-html="
            $t('Components.Reservation.ReservationOptions.Category_' + cat)
          "
        ></div>
        <table class="table is-fullwidth is-striped is-hoverable is-narrow">
          <thead>
            <tr>
              <th width="100">
                {{
                  $t('Components.Reservation.ReservationDetail.Table_Quantity')
                }}
              </th>
              <th>
                {{ $t('Components.Reservation.ReservationDetail.Table_Name') }}
              </th>
              <th width="175" class="has-text-left">
                {{ $t('Components.Reservation.ReservationDetail.Table_Space') }}
              </th>
              <th width="100" class="has-text-left">
                {{ $t('Components.Reservation.ReservationDetail.Table_Time') }}
              </th>
              <th width="100" class="has-text-right">
                {{ $t('Components.Reservation.ReservationDetail.Table_Price') }}
              </th>
              <th class="has-text-right" width="75">
                {{ $t('Components.Reservation.ReservationDetail.Table_Tax') }}
              </th>
              <th width="100" class="has-text-right">
                {{ $t('Components.Reservation.ReservationDetail.Table_Total') }}
              </th>
              <th width="85" class="is-hidden-print">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="option in reservation.Options.filter(
                (o) => o.CategoryId === cat
              )"
              :key="'o' + option.Id + 's' + option.SpaceId"
            >
              <td>{{ option.Amount }}x</td>
              <td>
                <span>{{ option.Name }}</span>
                <a @click="setShowOptionSummary(option.OptionId)">
                  <span class="icon is-small has-margin-left is-hidden-print">
                    <font-awesome-icon :icon="['fas', 'info-circle']" />
                  </span>
                </a>
                <div v-if="option.Id > 0">
                  <Notes
                    :locationId="reservation.LocationId"
                    :typeId="'ReservationOption'"
                    :itemId="option.Id"
                    :notes="option.Notes"
                    :linkTypeId="'Reservation'"
                    :linkItemId="reservation.Id"
                    :showNoNotesMessage="false"
                    :showAddLinkLeft="true"
                    :getFresh="false"
                    :viewType="'small'"
                  />
                </div>
              </td>
              <td>{{ option.SpaceName }}</td>
              <td>
                <span
                  v-if="option.TimeSelectable && option.SelectedTime > -1"
                  >{{ option.SelectedTime | minutesToTime }}</span
                >
              </td>
              <td class="has-text-right">
                <span v-if="!option.IsPackage">{{
                  option.PricePerItem
                    | toCurrency('en', reservation.CurrencyIso)
                }}</span>
              </td>
              <td class="has-text-right">
                <span
                  v-if="!option.IsPackage"
                  :class="{
                    'has-text-danger has-background-danger-light p-1 has-text-weight-bold':
                      option.TaxPercentage === 0,
                  }"
                  >{{ option.TaxPercentage }}%</span
                >
              </td>
              <td class="has-text-right">
                <span v-if="!option.IsPackage">{{
                  option.PriceTotal | toCurrency('en', reservation.CurrencyIso)
                }}</span>
              </td>
              <td width="85" class="is-hidden-print">
                <div v-if="!isLocked" class="field is-grouped is-grouped-right">
                  <p class="control">
                    <a @click="setEditOption(option)">
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <a @click="setDeleteOption(option)">
                      <span class="icon has-text-danger">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="cIndex === categories.length - 1">
            <tr>
              <td colspan="6" class="has-text-right">
                {{
                  $t('Components.Reservation.ReservationDetail.Table_Subtotal')
                }}
              </td>
              <td class="has-text-right">
                <span>{{
                  total | toCurrency('en', reservation.CurrencyIso)
                }}</span>
              </td>
              <td class="is-hidden-print">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'
import reservationProvider from '@/providers/reservation'

const Message = () => import('@/components/UI/Message')
const Notes = () => import('@/components/Notes/Notes')
const ModalAddOption = () => import('@/components/Reservations/ModalAddOption')
const ModalEditOption = () =>
  import('@/components/Reservations/ModalEditOption')
const ModalEditOptionSortOrder = () =>
  import('@/components/Reservations/ModalEditOptionSortOrder')
const ModalEditTotalSeats = () =>
  import('@/components/Reservations/ModalEditTotalSeats')
const ModalDeleteOption = () =>
  import('@/components/Reservations/ModalDeleteOption')
const OptionSummary = () => import('@/components/Options/OptionSummary')

export default {
  components: {
    Message,
    ModalAddOption,
    ModalEditOption,
    ModalEditOptionSortOrder,
    ModalDeleteOption,
    ModalEditTotalSeats,
    Notes,
    OptionSummary,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      drawerTitle: '',
      originalReservation: null,
      selectedOption: null,
      showAddOption: false,
      showEditOption: false,
      showEditTotalSeats: false,
      showDeleteOption: false,
      showEditSortOrder: false,
      showOptionSummary: false,
      showSideDrawer: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    categories() {
      let categories = []

      if (this.reservation && this.reservation.Options.length > 0) {
        for (let i = 0; i < this.reservation.Options.length; i++) {
          let option = this.reservation.Options[i]

          if (categories.findIndex((c) => c === option.CategoryId) === -1) {
            categories.push(option.CategoryId)
          }
        }
      }

      return categories.sort()
    },

    reservationHasPackageApplied() {
      let result = false

      if (this.reservation && this.reservation.Voucher) {
        result = this.reservation.Voucher.Type === 'Package'
      }

      return result
    },

    total() {
      let total = 0.0

      for (let i = 0; i <= this.reservation.Options.length; i++) {
        let option = this.reservation.Options[i]
        if (option) {
          if (!this.reservationHasPackageApplied || !option.InVoucher)
            total = total + option.PriceTotal
        }
      }

      return total
    },
  },

  watch: {
    nrOfSeats: function(newValue, oldValue) {
      let self = this

      if (newValue && newValue !== oldValue) {
        if (self.reservation && self.reservation !== null) {
          self.updateOptionTotals()
        }
      }
    },
  },

  created() {
    this.originalReservation = JSON.parse(JSON.stringify(this.reservation))
  },

  beforeDestroy() {},

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    cancelEditOption() {
      this.editSpace = null
    },

    delay(callback, ms) {
      let self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    updateOptionTotals() {
      let self = this
      let reservation = JSON.parse(JSON.stringify(self.reservation))

      if (reservation && reservation !== null) {
        for (let i = reservation.Options.length - 1; i >= 0; i--) {
          let option = reservation.Options[i]
          // TODO: Extra check if multiple spaces are available and
          // option is assigned to one of the spaces
          // If not option can be changed

          if (
            option.IsPP &&
            option.Amount === self.originalReservation.TotalSeats
          ) {
            option.Amount = Number(reservation.TotalSeats)
            option.PriceTotal = option.Amount * option.PricePerItem
            if (option.MaxTotal > 0 && option.PriceTotal > option.MaxTotal) {
              option.PriceTotal = option.MaxTotal
            }

            Vue.set(self.reservation.Options, i, option)
          }
        }
        // Save in DB using api
        self.saveReservation()
      }
    },

    saveReservation() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        reservationProvider.methods
          .saveReservation(self.reservation, false)
          .then((response) => {
            if (response.status === 200) {
              // Update reservation store
              self.setReservation(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Reservation data updated!',
              })

              // Update originalReservation data
              self.originalReservation = JSON.parse(
                JSON.stringify(response.data)
              )
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
            self.isSavingSuccess = true
          })
      }
    },

    setShowEditTotalSeats() {
      this.showEditTotalSeats = true
    },

    setShowAddOption() {
      this.showAddOption = true
    },

    setEditOption(option) {
      this.selectedOption = option
      this.showEditOption = true
    },

    setShowEditSortOrder() {
      this.showEditSortOrder = true
    },

    setDeleteOption(option) {
      this.selectedOption = option
      this.showDeleteOption = true
    },

    hideModal() {
      this.showAddOption = false
      this.showEditOption = false
      this.showDeleteOption = false
      this.showEditTotalSeats = false
      this.showEditSortOrder = false

      this.selectedOption = null
    },

    setShowOptionSummary(optionId) {
      this.itemId = optionId
      this.drawerTitle = 'Option detail'
      this.showSideDrawer = true
      this.showOptionSummary = true
    },

    hideDrawer() {
      this.itemId = 0
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.showOptionSummary = false
    },
  },
}
</script>
